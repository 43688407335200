import React, { createContext, useEffect, useState, useMemo } from 'react'

import useAuth from '@/hooks/useAuth'

import PropTypes from 'prop-types'
import useUserAuthStore, { ACTIONS } from '@/store/userAuthStore'
import Cookies from 'universal-cookie'
import { useKeycloak } from '@react-keycloak/web'

const AuthContext = createContext()

function AuthContextProvider({ children }) {
  const { state, dispatch } = useUserAuthStore()
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const cookies = new Cookies()
  const { getCurrentUser } = useAuth()
  const { initialized, keycloak } = useKeycloak()
  useEffect(() => {
    if (!initialized) return
    if (!keycloak.authenticated) {
      keycloak.login()
    }
    getCurrentUser().then((currentUser) => {
      setUser(currentUser)
      dispatch({ type: ACTIONS.setUser, payload: currentUser })
      dispatch({ type: ACTIONS.setToken, payload: cookies.get('accessToken') })

      setLoading(false)
      if (
        !currentUser
        || (!currentUser?.platform?.profitability
          && currentUser?.platform?.profitability.toLowerCase() === 'na')
      ) {
        alert('You do not have access to this platform')
        window.location.href = process.env.REACT_APP_INTRA_URL
      }
    })
  }, [initialized, keycloak])

  const contextValue = useMemo(
    () => ({ user, setUser, loading, dispatch, state }),
    [user, setUser, loading, dispatch, state]
  )

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { AuthContext, AuthContextProvider }
