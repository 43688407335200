/* eslint-disable */
import React, { useState } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import ToastMessage from '@/components/common/ToastMessage'
import { ThemeProvider } from '@emotion/react'
import theme from '@/mui/theme'
import { BrowserRouter as Router, useRoutes } from 'react-router-dom'

import UIContext from '@/context/uiContext'
import { Alert, Backdrop, CircularProgress, Snackbar } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import Error from '@/view/Error'
import useUIStore, { ACTIONS } from './store/uiStore'
import ConfirmDialog from './components/common/ConfirmDialog'
import ProtectedRoute from './components/guard/ProtectedRoute'
import CreateTace from './view/tace_generator/CreateTace'
import TaceList from './view/tace_generator'
import LeaveDayOff from './view/leaveDayOff/payfit'
import StaffReportManagement from './view/staffReport'
import RevenueReport from './view/RevenueReport'
import Cookie from 'universal-cookie'
import { AuthContextProvider } from './context/AuthContextProvider'
import keycloakAuthClient from './keycloak'
import { ReactKeycloakProvider } from '@react-keycloak/web'

const CreateCosting = React.lazy(() => import('./view/client/CreateCosting'))
const EmployeePrice = React.lazy(() => import('./view/EmployeePrice'))
const Role = React.lazy(() => import('./view/Role'))
const Client = React.lazy(() => import('./view/client/Client'))
const Project = React.lazy(() => import('./view/client/Project'))
const Login = React.lazy(() => import('./view/Login'))

const MainView = () => {
  const routes = useRoutes([
    {
      path: '',
      element: (
        <ProtectedRoute>
          <Client />
        </ProtectedRoute>
      ),
      index: true,
    },
    {
      path: 'client',
      children: [
        {
          path: ':name/:id',
          element: (
            <ProtectedRoute>
              <Project />
            </ProtectedRoute>
          ),
        },
        {
          path: 'create-costing',
          element: (
            <ProtectedRoute>
              <CreateCosting />
            </ProtectedRoute>
          ),
        },
        {
          path: '',
          element: (
            <ProtectedRoute>
              <Client />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: 'staff_report_management',
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <StaffReportManagement />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: 'leave_holiday',
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <LeaveDayOff />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: 'tace_generator',
      children: [
        {
          path: ':name/:id',
          element: (
            <ProtectedRoute roleAllowed={['ADMIN']}>
              <CreateTace />
            </ProtectedRoute>
          ),
        },
        {
          path: 'create-tace',
          element: (
            <ProtectedRoute roleAllowed={['ADMIN']}>
              <CreateTace />
            </ProtectedRoute>
          ),
        },
        {
          path: '',
          element: (
            <ProtectedRoute roleAllowed={['ADMIN']}>
              <TaceList />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: 'revenue_forecast',
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute roleAllowed={['ADMIN']}>
              <RevenueReport />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: 'resources',
      element: (
        <ProtectedRoute>
          <EmployeePrice />
        </ProtectedRoute>
      ),
    },
    {
      path: 'roles',
      element: (
        <ProtectedRoute>
          <Role />
        </ProtectedRoute>
      ),
    },
    { path: 'error', element: <Error /> },
    { path: 'login', element: <Login /> },
  ])
  return routes
}

function App() {
  const { state, dispatch } = useUIStore()
  const [keycloakInitialized, setKeycloakInitialized] = useState(false)

  const handleClose = () => {
    dispatch({
      type: ACTIONS.toggleLoading,
      payload: true,
    })
  }

  const cookies = new Cookie()
  const handleOnToken = (token) => {
    cookies.set('accessToken', token.token, {
      domain: process.env.REACT_APP_SUB_DOMAIN,
    })
    cookies.set('refreshToken', token.refreshToken, {
      domain: process.env.REACT_APP_SUB_DOMAIN,
    })
    setKeycloakInitialized(true)
  }

  const handleKeycloakEvent = (event, error) => {
    if (event === 'onAuthSuccess' || event === 'onReady') {
      setKeycloakInitialized(true)
    }
    if (event === 'onAuthLogout') {
      setKeycloakInitialized(false)
    }
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloakAuthClient}
      onTokens={handleOnToken}
      onEvent={handleKeycloakEvent}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
      }}
    >
      <Router>
        <AuthContextProvider>
          <UIContext.Provider
            value={{
              state,
              dispatch,
            }}
          >
            <ToastMessage
              message={state.toastMessage.message}
              type={state.toastMessage.type}
              open={state.toastMessage.message !== ''}
              handleClose={() =>
                dispatch({
                  type: ACTIONS.setToastMessage,
                  payload: { message: '' },
                })
              }
            />
            <ConfirmDialog
              open={state.confirmDialog.open}
              refreshData={state.confirmDialog.refreshData}
              callback={state.confirmDialog.callback}
              description={state.confirmDialog.description}
            />
            <ThemeProvider theme={theme}>
              {keycloakInitialized ? (
                <React.Suspense
                  fallback={
                    <Backdrop
                      sx={{ color: '#fff', zIndex: (t) => t.zIndex.drawer + 1 }}
                      open
                      onClick={handleClose}
                    >
                      <CircularProgress color="primary" />
                    </Backdrop>
                  }
                >
                  {state.loading &&
                    (state.linearLoading ? (
                      <LinearProgress className="my-4" />
                    ) : (
                      <Snackbar
                        open={state.loading}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        <Alert
                          icon={
                            <CircularProgress color="primary" size="1.5rem" />
                          }
                          severity="info"
                          sx={{ width: '100%' }}
                        >
                          Loading
                        </Alert>
                      </Snackbar>
                    ))}

                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MainView />
                  </LocalizationProvider>
                </React.Suspense>
              ) : (
                <Backdrop
                  sx={{ color: '#fff', zIndex: (t) => t.zIndex.drawer + 1 }}
                  open
                >
                  <CircularProgress color="primary" />
                </Backdrop>
              )}
            </ThemeProvider>
          </UIContext.Provider>
        </AuthContextProvider>
      </Router>
    </ReactKeycloakProvider>
  )
}

export default App
