import React, { useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
// import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import { Add } from '@mui/icons-material'
import { useAsyncRequest } from '@/utils/helper'
// import { TextField } from '@mui/material'
import TableAction from '@/components/common/TableAction'
import folderApi from '@/api/folder'
import { FormControl, TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import TableHeadSortable, { getComparator } from '@/components/common/TableHeadSort'

import moment from 'moment'

function LeaveDayOff() {
  const ExportPayfitFolderId = process.env.REACT_APP_PAYFIT_FOLDER_ID || '1EeJme1qOeweksamTYn9TXJWznrHI7kpe'
  const { actionWrapper } = useAsyncRequest()
  const [isCreateFolderOpen, setIsCreateFolderOpen] = useState(false)
  const [files, setFiles] = useState([])
  const [selectedFile, setSelectedFile] = useState(null)
  const [filename, setFilename] = useState(null)
  const [selectedDate, setSelectedDate] = useState(moment())
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('name')
  const [search, setSearch] = React.useState('')

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  const headCells = [
    {
      id: 'name',
      label: 'File Name',
    },
    {
      id: 'properties.exportDate',
      label: 'Export Date',
    },
    {
      id: 'empty',
      diabledSort: true,
    },
  ]

  function searchFilename(k) {
    if (search === '') {
      return k
    }
    const format = new RegExp(search, 'gi')
    return k.filter((filen) => filen.name.match(format))
  }

  async function fetchFiles() {
    const res = await actionWrapper(
      () => folderApi.listFiles(ExportPayfitFolderId),
      {},
      true
    )
    setFiles(res.files)
  }
  async function handleCreate(event) {
    event.preventDefault()
    const formData = new FormData()

    formData.append('file', selectedFile)
    formData.append('filename', filename)
    formData.append('exportDate', selectedDate.add(7, 'days').toISOString())
    await actionWrapper(() => folderApi.uploadCsv(formData), {
      showMessage: true,
    })
    const k = await folderApi.listFiles(ExportPayfitFolderId)
    setFiles(k.files)
    setIsCreateFolderOpen(false)
  }
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
  }
  const handleFilenameChange = (event) => {
    setFilename(event.target.value)
  }
  const fetchData = async () => {
    const k = await folderApi.listFiles(ExportPayfitFolderId)
    setFiles(k.files)
  }

  const handleDeleteFile = async (file) => {
    await actionWrapper(() => folderApi.deleteFile(file.id), {
      showMessage: true,
      isConfirm: true,
      refreshData: fetchData,
      description: 'The file will be deleted, Please check before confirming'
    })
  }
  async function handleOpenCreateFolderDialog() {
    setIsCreateFolderOpen(true)
  }

  async function handleCloseCreateFolderDialog() {
    setIsCreateFolderOpen(false)
  }

  useEffect(() => {
    actionWrapper(() => fetchFiles())
  }, [])

  return (
    <div>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <div className="text-primary font-bold text-2xl">Leaves & Holidays</div>
        <div className="space-x-4">
          <Button
            startIcon={<Add />}
            onClick={handleOpenCreateFolderDialog}
            variant="contained"
          >
            Upload Leaves & Holidays
          </Button>
        </div>
        <Dialog
          onClose={handleCloseCreateFolderDialog}
          open={isCreateFolderOpen}
        >
          <DialogTitle>
            <InputLabel>Payfit Export</InputLabel>
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleCreate} className="w-60">
              <FormControl className="my-4 flex flex-col space-y-5">
                <TextField
                  type="text"
                  name="name"
                  value={filename}
                  onChange={handleFilenameChange}
                  placeholder="e.g Dec-2022-kh"
                  required
                  label="File name"
                  fullWidth
                />
                <TextField type="file" onChange={handleFileChange} required />
                <DesktopDatePicker
                  inputFormat="MM/yyyy"
                  views={['year', 'month']}
                  label="Export Date"
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      required
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          backgroundColor: 'white',
                        },
                      }}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: 'MM/YYYY',
                      }}
                    />
                  )}
                />
              </FormControl>
              <div className="mt-4 flex justify-end">
                <Button type="submit" variant="contained">
                  Upload
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </Stack>
      <div className=" flex space-x-2 mt-4">
        <TextField
          size="medium"
          value={search}
          variant="standard"
          onChange={(e) => setSearch(e.target.value)}
          title="Filename"
          label="Filename"
        />
      </div>
      {/* <Autocomplete
        disablePortal
        multiple
        id="combo-box-demo"
        options={files}
        value={}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label="Filename" />}
      /> */}
      <TableContainer className="mt-10" component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHeadSortable
            onRequestSort={handleRequestSort}
            order={order}
            headCells={headCells}
            orderBy={orderBy}
          />
          <TableBody>
            {searchFilename(files)
              .sort(getComparator(order, orderBy))
              .map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() =>
                    window
                      .open(
                        `https://drive.google.com/file/d/${row.id}`,
                        '_blank'
                      )
                      .focus()
                  }
                  className="hover:shadow-md hover:cursor-pointer"
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {moment(row.properties?.exportDate).format('MM/YYYY')}
                  </TableCell>
                  <TableCell align="right">
                    <TableAction
                      file={row}
                      handleDelete={() => handleDeleteFile(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default LeaveDayOff
