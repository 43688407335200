import React, { useEffect, useState } from 'react'

import Button from '@mui/material/Button'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import { Add } from '@mui/icons-material'
import { toDefaultDateFormat, useAsyncRequest } from '@/utils/helper'
import { TextField } from '@mui/material'
import TableAction from '@/components/common/TableAction'
import folderApi from '@/api/folder'
import PropTypes from 'prop-types'
import TableHeadSortable, {
  getComparator,
} from '@/components/common/TableHeadSort'

function FileManagment({ folderId, folderType }) {
  const [folders, setFolders] = useState([])
  const { actionWrapper } = useAsyncRequest()
  const [isCreateFolderOpen, setIsCreateFolderOpen] = useState(false)
  const [folderName, setFolderName] = useState('')
  const [search, setSearch] = React.useState('')
  const [order, setOrder] = React.useState('asc')
  const [orderBy, setOrderBy] = React.useState('name')
  const headCells = [
    {
      id: 'name',
      label: 'Name',
    },

    {
      id: 'createdTime',
      label: 'Date',
    },
    {
      id: 'empty',
      diabledSort: true,
    },
  ]
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }
  async function fetchFolders() {
    const res = await actionWrapper(
      () => folderApi.listFoldersById(folderId),
      {},
      true
    )
    setFolders(res.files)
  }

  async function handleDeleteFolder(folder) {
    await actionWrapper(() => folderApi.deleteFile(folder.id), {
      showMessage: true,
      isConfirm: true,
      refreshData: fetchFolders,
      description: 'The folder will be deleted, Please check before confirming',
    })
  }

  async function handleCreate(event) {
    event.preventDefault()
    await actionWrapper(
      () =>
        folderApi.createFolder({
          fileName: folderName,
          parentFolderId: folderId,
        }),
      { showMessage: true }
    )
    await fetchFolders()
    setFolderName('')
    setIsCreateFolderOpen(false)
  }

  async function handleOpenCreateFolderDialog() {
    setIsCreateFolderOpen(true)
  }

  async function handleCloseCreateFolderDialog() {
    setIsCreateFolderOpen(false)
  }

  async function handleFolderNameChange(event) {
    setFolderName(event.target.value)
  }
  function onSearch(k) {
    if (search === '') {
      return k
    }
    const format = new RegExp(search, 'gi')
    return k.filter((filen) => filen.name.match(format))
  }
  useEffect(() => {
    actionWrapper(() => fetchFolders())
  }, [])

  return (
    <div>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <div className="text-primary font-bold text-2xl">{folderType}</div>
        <div className="space-x-4">
          <Button
            startIcon={<Add />}
            onClick={handleOpenCreateFolderDialog}
            variant="contained"
          >
            Create Folder
          </Button>
        </div>
        <Dialog
          onClose={handleCloseCreateFolderDialog}
          open={isCreateFolderOpen}
        >
          <DialogTitle>
            <InputLabel>New Folder</InputLabel>
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleCreate} className="w-60">
              <div className="my-4">
                <TextField
                  type="text"
                  name="name"
                  value={folderName}
                  onChange={handleFolderNameChange}
                  placeholder="e.g New Folder"
                  required
                  label="Folder name"
                  fullWidth
                />
              </div>
              <div className="flex justify-end">
                <Button type="submit" variant="contained">
                  Create
                </Button>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </Stack>
      <div className=" flex space-x-2 mt-4">
        {/* <Typography className="py-3">Search: </Typography> */}
        <TextField
          size="medium"
          value={search}
          variant="standard"
          onChange={(e) => setSearch(e.target.value)}
          title="Filename"
          label="Filename"
        />
      </div>
      <TableContainer className="my-10" component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHeadSortable
            onRequestSort={handleRequestSort}
            order={order}
            headCells={headCells}
            orderBy={orderBy}
          />

          <TableBody>
            {onSearch(folders)
              .sort(getComparator(order, orderBy))
              .map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  onClick={() =>
                    window
                      .open(
                        `https://drive.google.com/drive/folders/${row.id}`,
                        '_blank'
                      )
                      .focus()
                  }
                  className="hover:shadow-md hover:cursor-pointer"
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{toDefaultDateFormat(row.createdTime)}</TableCell>
                  <TableCell align="right">
                    <TableAction
                      file={row}
                      handleDelete={() => handleDeleteFolder(row)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

FileManagment.propTypes = {
  folderId: PropTypes.string.isRequired,
  folderType: PropTypes.string.isRequired,
}

export default FileManagment
