import httpClient from '@/utils/http-client'

const resouce = 'drive-profitability'
const folderApi = {
  async listFolder(params) {
    const res = await httpClient({
      url: `${resouce}/list-folder`,
      method: 'GET',
      params,
    })
    return res.data
  },

  async listFoldersById(folderId) {
    const res = await httpClient({
      url: `${resouce}/list-folder/${folderId}`,
      method: 'GET',
    })
    return res.data
  },

  async listFiles(folderId) {
    const res = await httpClient({
      url: `${resouce}/list-file/${folderId}`,
      method: 'GET',
    })
    return res.data
  },

  async deleteFile(fileId) {
    const res = await httpClient({
      url: `${resouce}/delete-file/${fileId}`,
      method: 'DELETE',
    })
    return res.data
  },

  async deleteMultiFile(data) {
    const res = await httpClient({
      url: `${resouce}/delete-multi-file`,
      method: 'DELETE',
      data,
    })
    return res.data
  },

  async publicSheet(sheetId) {
    const res = await httpClient({
      url: `${resouce}/public-file/${sheetId}`,
      method: 'POST',
    })
    return res.data
  },

  async createFolder(data) {
    const res = await httpClient({
      url: `${resouce}/create-folder`,
      method: 'POST',
      data,
    })
    return res.data
  },

  async uploadCsv(data) {
    const res = await httpClient({
      url: `${resouce}/upload-csv`,
      method: 'POST',
      data,
      // headers: { 'Content-Type': 'multipart/form-data' },
    })
    return res.data
  },
}
export default folderApi
