import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { AuthContext } from '@/context/AuthContextProvider'
import MainLayout from '../layout/MainLayout'

function ProtectedRoute({ roleAllowed, children }) {
  const { user } = useContext(AuthContext)

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (user && roleAllowed) {
      if (
        !user?.platform?.profitability
       || (roleAllowed?.length > 0
        && !roleAllowed.includes(user.platform.profitability.toLowerCase()))
      ) {
        return <Navigate to="/" />
      }
    }
  }, [user])
  return <MainLayout>{children}</MainLayout>
}

ProtectedRoute.propTypes = {
  children: PropTypes.any.isRequired,
  roleAllowed: PropTypes.array,
}
ProtectedRoute.defaultProps = {
  roleAllowed: [],
}
export default ProtectedRoute
